import React, {useEffect} from 'react'
import './styles/App.scss'
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loader from './components/helpers/Loader'
import 'react-toastify/dist/ReactToastify.css'
import '@fontsource/poppins'

import { useDispatch, useSelector } from 'react-redux'
import { getCurrencies} from './store/actions/currency'


// load user page
const User = Loadable({
  loader: () => import('./components/user'),
  loading: Loader,
  delay: 100 // 0.1 seconds
})

const Admin = Loadable({
  loader: () => import('./components/admin'),
  loading: Loader,
  delay: 100 // 0.1 seconds
})

function App () {

  return (
    <div>
      <Switch>
        <Route path='/admin' component={Admin} />
        <Route path='/' component={User} />
      </Switch>
    </div>
  )
}

export default App
